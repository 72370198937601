import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import CurrentCoin from "./CurrentCoin/reducer"

const store = configureStore({
    reducer: {
        CurrentCoin
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),

})


export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
