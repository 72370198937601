import {createReducer} from "@reduxjs/toolkit";
import {upDataCurrency} from "./actions";
import USDCImg from "../../assets/imgs/USDC@2x.png"
import maticImg from "../../assets/imgs/matic@2x.png"

const CURRENCYKEY = "CURRENCYKEY"

export interface CurrencyState {
    name: string,
    id: number,
    img:string,
    decimals:number,

}

const currencyList: CurrencyState[] = [
    {
        name: "Matic",
        id: 0,
        img:maticImg,
        decimals:18,
    },
    {
        name: "USDC",
        id: 1,
        img:USDCImg,
        decimals:6,
    },
]

function initialState(): {
    currency: CurrencyState,
    currencyList: CurrencyState[]
} {
    let _data: any = localStorage.getItem(CURRENCYKEY);
    let initData = {
        name: "Matic",
        id: 0,
        img:maticImg,

    }
    if (!_data) {
        localStorage.setItem(CURRENCYKEY, JSON.stringify(initData))
    }
    _data = _data ? JSON.parse(_data) : initData
    return {currency: _data, currencyList: currencyList}

}

export default createReducer(initialState(), builder =>
    builder
        .addCase(upDataCurrency, (state, action) => {
            localStorage.setItem(CURRENCYKEY, JSON.stringify(action.payload))
            state.currency = action.payload;
        })
)
