import styled from "styled-components";
import {StartStyle} from "./start_style";
import React from "react";
import {goToHref} from "../../base/net_select";

export const SwitchNetButton = () =>{
    return (
        <ButtonStyle onClick={goToHref}>
            Switch to Polygon to Start Mining
        </ButtonStyle>
    );
}

const ButtonStyle = styled.div`
  ${StartStyle.ButtonStyle};
  background: #AA79E9;
  border-radius: 4px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
`
